<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :closable="false"
      width="700px"
      title="订单与套餐详情"
    >
      <template slot="footer">
        <a-button
          class="cancel-btn"
          @click="closeModal"
          style="font-size: 14px;"
        >
          关闭
        </a-button>
      </template>

      <div>
        <a-descriptions
          :column="1"
          class="custom-descriptions"
        >
          <a-descriptions-item label="关联订单">
            {{ data.order_no }}
          </a-descriptions-item>
        </a-descriptions>
        <a-row :gutter="12">
          <a-col span="12">
            <a-descriptions
              :column="1"
              class="custom-descriptions custom-descriptions-max-5"
            >
              <a-descriptions-item label="订单负责人">
                {{ data.order_user_name }}
              </a-descriptions-item>
              <a-descriptions-item label="订单申请人">
                {{ data.applicant_name }}
              </a-descriptions-item>
              <a-descriptions-item label="厅房名称">
                {{ data.hall_name }}
              </a-descriptions-item>
              <a-descriptions-item label="出厅时间">
                {{ data.leave_hall_time }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col span="12">
            <a-descriptions
              :column="1"
              class="custom-descriptions custom-descriptions-max-5"
            >
              <a-descriptions-item label="负责人电话">
                <span class="custom-word-break-all">{{ data.order_user_phone_number }}</span>
              </a-descriptions-item>
              <a-descriptions-item label="申请人电话">
                <span class="custom-word-break-all">{{ data.applicant_phone }}</span>
              </a-descriptions-item>
              <a-descriptions-item label="进厅时间">
                {{ data.to_hall_time }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
        </a-row>
        <span class="goods-title">套餐详情:</span>
        <!--货品清单-->
        <a-table
          :columns="columns"
          :data-source="data.items"
          :loading="loadingItems"
          :pagination="false"
          :bordered="true"
          style="margin-top: 10px;"
          row-key="id"
        >
          <span slot="item_type" slot-scope="text">
            <span style="color: #f59a23;">{{ text }}</span>
          </span>
        </a-table>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { findFlowerServiceOrderPackage } from '@/api/service_order_package'
export default {
  name: 'ElegiacCouplet',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      data: {},
      loadingItems: true
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },

    columns() {
      return [
        {
          title: '项目名称',
          dataIndex: 'item_name',
          width: 200
        },
        {
          title: '项目类型',
          dataIndex: 'item_type',
          scopedSlots: { customRender: 'item_type' }
        },
        {
          title: '计价单位',
          dataIndex: 'unit'
        },
        {
          title: '计价个数',
          dataIndex: 'count'
        }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loadingItems = true
      findFlowerServiceOrderPackage(this.id).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
      })
      this.loadingItems = false
    },

    closeModal() {
      this.isShow = false
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .ant-modal-content {
  background-color: #2a4c39;
}

/deep/ .ant-modal-header {
  border-bottom: 1px solid #848484;
  background-color: #2a4c39 !important;

  .ant-modal-title {
    font-size: 20px;
    color: @white !important;
  }
}

/deep/ .ant-modal-body {
  color: @white;
}

/deep/ .ant-modal-footer {
  border-top: none;
  text-align: center;
  background-color: #2a4c39 !important;
}

.cancel-btn {
  width: 150px;
  font-size: 20px !important;
  border: none;
}

/deep/ .submit-btn.ant-btn:hover {
  color: rgba(0, 0, 0, 65%);
}

/deep/ .submit-btn.ant-btn:focus {
  color: rgba(0, 0, 0, 65%);
}

/deep/ .ant-divider-horizontal {
  background-color: #848484;
}

.goods-title {
  font-size: 20px;
  color: #f59a23;
}
</style>
